import React from "react"

import { keyframes } from 'styled-components';
import styled from 'styled-components';

const ImageAnimation = keyframes`
    0% { opacity: 0; animation-timing-function: ease-in;}
    8% { opacity: 1; animation-timing-function: ease-out;}
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
`;

const Slideshow = styled.ul`
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -1; 
`;

const SlideshowItemContent = styled.span`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: ${props => props.xPercent} ${props => props.yPercent};
    background-repeat: none;
    background-image: url(${props => props.bgImage});
    opacity: 0;
    z-index: -1;
    animation: ${ImageAnimation} ${props => props.animationDuration}s linear infinite ${props => props.animationDelay}s; 

`;

const BGSlideshow = ({ entries }) => (
        <Slideshow>
            {entries.map((entry, key) => 
                <li style={{zIndex:0}} key={key}>
                    <SlideshowItemContent bgImage={entry.bgImage} animationDelay={entry.animationDelay} animationDuration={entry.animationDuration} xPercent={entry.xPercent} yPercent={entry.yPercent}>Item</SlideshowItemContent>
                </li>
            )}

        </Slideshow>
)

export default BGSlideshow;